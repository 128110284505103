import { I18nextProvider } from 'react-i18next';
import i18n from './i18h'; // Your i18n configuration
import ScrollToTop from "./components/ScrollToTop";
import { Outlet } from "react-router-dom"; // Import Outlet
import CookieConsent from './components/CookieConsent';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <CookieConsent />
      <ScrollToTop />
      <Outlet /> {/* This will render the matched child route component */}
    </I18nextProvider>
  );
}

export default App;
