import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    // const handleReject = () => {
    //     localStorage.removeItem('cookieConsent');
    //     window.location.reload();
    //     setIsVisible(false);
    // };


    return (
        isVisible && (
            <div className="fixed bottom-0 w-full bg-gray-800 text-white p-4 flex items-center justify-between z-50">
                <p className="text-sm">
                    We use cookies to improve your experience on our site. By continuing to use our site, you accept our use of cookies.
                    <a href="/privacy-policy" className="underline ml-2">
                        Learn more
                    </a>
                </p>
                <button
                    onClick={handleAccept}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-4"
                >
                    Accept
                </button>
{/* 
                <button
                    onClick={handleReject}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-4"
                >
                    Reject
                </button> */}
            </div>
        )
    );
};

export default CookieConsent;
